<template>
  <div
    v-infinite-scroll="fetchTransactions"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="80"
    class="mt-10 mb-28"
  >
    <div v-if="showComponent" class=" mb-10">
      <t-filter-select
        v-model="state"
        class="w-40 inline-block"
        placeholder="All States"
        :options="[
          { value: 'PENDING', label: 'Pending' },
          { value: 'COMPLETED', label: 'Completed' },
          { value: 'FAILED', label: 'Failed' },
          { value: 'OUTBID', label: 'Outbid' },
          { value: 'EXPIRED', label: 'Expired' },
        ]"
      />
      <t-filter-select
        v-model="asset"
        class="-bg-right-20 w-40 inline-block"
        placeholder="All Assets"
        :options="currencyOptions"
      >
        <template slot="arrow"> </template>
      </t-filter-select>
      <t-filter-select
        v-model="type"
        placeholder="All Types"
        class="w-40 inline-block"
        :options="[
          { value: 'BUY_NFT', label: 'NFT Purchase' },
          { value: 'DEPOSIT', label: 'Deposit' },
          { value: 'BID', label: 'Offer' },
          { value: 'WITHDRAWAL', label: 'Withdrawal' },
        ]"
      />

      <button
        class="uppercase border-b-2 border-transparent hover:border-gray-300 hover:text-gray-600 inline-block ml-3.5 sm:ml-12"
        @click="resetFilters"
      >
        reset
      </button>
    </div>

    <table
      v-if="!renderResponsive && showComponent"
      class="w-full table-fixed divide-y divide-gray-200 shadow-sm border-gray-200 border text-14"
    >
      <thead class="divide-y divide-gray-200 ">
        <th
          v-for="(header, headerIndex) in headers"
          :key="headerIndex"
          :class="
            `px-3 py-3 text-gray-800 text-12 leading-4 font-medium tracking-wider text-left bg-gray-50 uppercase ${
              header === 'Item'
                ? 'w-44 lg:w-auto'
                : header === 'Date'
                ? 'lg:w-40'
                : 'lg:w-32'
            }`
          "
        >
          {{ header }}
        </th>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200 border-l border-r">
        <tr v-for="(row, rowIndex) in data" :key="rowIndex" class="relative">
          <td>
            <div
              :class="
                `${
                  row.isAccountAction ? 'cursor-pointer' : ''
                } h-16 lg:h-14 px-3 py-3 whitespace-no-wrap`
              "
              @click="openRow(rowIndex, row.isAccountAction)"
            >
              {{ row.date }}
              <p class="text-12 text-blue-400">{{ row.detailDate }}</p>
            </div>
            <div
              v-if="openRows.includes(rowIndex)"
              class="mt-2 pl-3 mb-2 space-y-2"
            >
              <p class="text-12 whitespace-nowrap">
                Transfer ID:
                <a
                  class="underline cursor-pointer"
                  target="_blank"
                  :href="'https://ropsten.etherscan.io/tx/' + row.transaction"
                  >{{ row.transaction }}</a
                >
              </p>
              <p class="text-12 whitespace-nowrap">Sender: {{ row.sender }}</p>
              <p class="text-12 whitespace-nowrap">
                Receiver: {{ row.receiver }}
              </p>
            </div>
          </td>
          <td class="align-top">
            <div
              :class="
                `${
                  row.isAccountAction ? 'cursor-pointer' : ''
                } h-16 lg:h-14 pt-5 pb-3 px-3 whitespace-no-wrap capitalize`
              "
              @click="openRow(rowIndex, row.isAccountAction)"
            >
              {{ row.state }}
            </div>
          </td>
          <td class="align-top ">
            <div
              :class="
                `${
                  row.isAccountAction ? 'cursor-pointer' : ''
                } h-16 lg:h-14 pt-5 pb-3 px-3 whitespace-no-wrap truncate`
              "
              @click="openRow(rowIndex, row.isAccountAction)"
            >
              {{ formatAmount(row.amount) }}
            </div>
          </td>
          <td class="align-top">
            <div
              :class="
                `${
                  row.isAccountAction ? 'cursor-pointer' : ''
                } h-16 lg:h-14 pt-5 pb-3 px-3 whitespace-no-wrap truncate`
              "
              @click="openRow(rowIndex, row.isAccountAction)"
            >
              {{ row.asset }}
            </div>
          </td>
          <td class="align-top">
            <div
              :class="
                `${
                  row.isAccountAction ? 'cursor-pointer' : ''
                } h-16 lg:h-14 pt-5 pb-3 px-3 whitespace-no-wrap truncate`
              "
              @click="openRow(rowIndex, row.isAccountAction)"
            >
              {{ row.transactionType }}
            </div>
          </td>
          <td v-if="row.isAccountAction && row.itemName" class="align-top">
            <div
              :class="
                `align-top pt-3 pb-3 px-3 whitespace-no-wrap truncate ${
                  row.isAccountAction ? 'cursor-pointer' : ''
                } h-16 lg:h-14 relative`
              "
              @click="openRow(rowIndex, row.isAccountAction)"
            >
              <div
                class="flex h-9 w-3/4 "
                @click="redirectToDetails(row.assetID)"
              >
                <div class="block box-10 w-10 h-10 overflow-hidden">
                  <img
                    :src="row.itemImg"
                    alt="nft token"
                    class="object-cover pointer-events-none"
                  />
                </div>
                <p class="ml-2 whitespace-nowrap my-auto truncate">
                  {{ row.itemName }}
                </p>
              </div>
              <img
                src="@/assets/icons/arrow_table.svg"
                alt="arrow"
                :class="{ 'rotate-180': !openRows.includes(rowIndex) }"
                class="transform w-4 h-4 absolute right-5 lg:right-8 top-5"
              />
            </div>
          </td>
          <td v-else-if="row.isAccountAction" class="align-top">
            <div
              :class="
                `${
                  row.isAccountAction ? 'cursor-pointer' : ''
                } h-16 lg:h-14 pt-5 pb-3 px-3 whitespace-no-wrap truncate relative`
              "
              @click="openRow(rowIndex, row.isAccountAction)"
            >
              <img
                src="@/assets/icons/arrow_table.svg"
                alt="arrow"
                :class="{ 'rotate-180': !openRows.includes(rowIndex) }"
                class="transform w-4 h-4 absolute right-5 lg:right-8"
              />
            </div>
          </td>
          <td
            v-else
            class="align-top pt-3 pb-3 px-3 whitespace-no-wrap truncate cursor-pointer"
            @click="redirectToDetails(row.assetID)"
          >
            <div class="flex h-9">
              <div class="block box-10 h-10 w-10 overflow-hidden">
                <img
                  :src="row.itemImg"
                  alt="nft token"
                  class="object-cover pointer-events-none"
                />
              </div>
              <p class="ml-2 whitespace-nowrap my-auto truncate">
                {{ row.itemName }}
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table
      v-if="renderResponsive && showComponent"
      class="w-full table-fixed border-none"
    >
      <tbody>
        <template v-for="(row, rowIndex) in data">
          <tr :key="rowIndex + '' + 0" class="border-2">
            <td class="px-2 py-2 w-28">
              <strong>{{ headers[0] }}</strong>
            </td>
            <td class="truncate block px-2 py-2 w-100">
              {{ row.date }}
              <div class="text-12 text-blue-400">{{ row.detailDate }}</div>
            </td>
          </tr>
          <tr :key="rowIndex + '' + 1" class="border-2">
            <td class="px-2 py-2 w-28">
              <strong>{{ headers[1] }}</strong>
            </td>
            <td class="truncate block px-2 py-2 w-100 capitalize">
              {{ row.state }}
            </td>
          </tr>
          <tr :key="rowIndex + '' + 2" class="border-2">
            <td class="px-2 py-2 w-28">
              <strong>{{ headers[2] }}</strong>
            </td>
            <td class="truncate block px-2 py-2 w-100">
              {{ formatAmount(row.amount) }}
            </td>
          </tr>
          <tr :key="rowIndex + '' + 3" class="border-2">
            <td class="px-2 py-2 w-28">
              <strong>{{ headers[3] }}</strong>
            </td>
            <td class="truncate block px-2 py-2 w-100">
              {{ row.asset }}
            </td>
          </tr>
          <tr :key="rowIndex + '' + 4" class="border-2">
            <td class="px-2 py-2 w-28">
              <strong>{{ headers[4] }}</strong>
            </td>
            <td class="truncate block px-2 py-2 w-100">
              {{ row.transactionType }}
            </td>
          </tr>

          <tr
            v-if="row.isAccountAction"
            :key="rowIndex + '' + 5"
            class="border-2"
          >
            <td class="px-2 py-2 w-28">
              <p>Transfer ID:</p>
              <p>Sender:</p>
              <p>Receiver:</p>
            </td>
            <td class="truncate block px-2 pt-3.5 w-100 space-y-2">
              <p class="text-12 whitespace-nowrap truncate">
                <a
                  class="underline cursor-pointer"
                  target="_blank"
                  :href="'https://ropsten.etherscan.io/tx/' + row.transaction"
                  >{{ row.transaction }}</a
                >
              </p>
              <p class="text-12 whitespace-nowrap truncate">{{ row.sender }}</p>
              <p class="text-12 whitespace-nowrap truncate">
                {{ row.receiver }}
              </p>
            </td>
          </tr>
          <tr v-else :key="rowIndex + '' + 5" class="border-2">
            <td class="px-2 py-2 w-28">
              <strong>{{ headers[5] }}</strong>
            </td>
            <td class="truncate block px-2 py-2 w-100">
              <div
                class="flex h-9 items-center cursor-pointer"
                @click="redirectToDetails(row.assetID)"
              >
                <div class="block box-10 h-10 w-10 overflow-hidden">
                  <img
                    :src="row.itemImg"
                    alt="nft token"
                    class="object-cover pointer-events-none"
                  />
                </div>
                <p class="ml-2 text-14 whitespace-nowrap truncate">
                  {{ row.itemName }}
                </p>
              </div>
            </td>
          </tr>

          <tr
            v-if="rowIndex < data.length - 1"
            :key="`${rowIndex}_`"
            class="h-10 border-r-0 border-l-0"
          >
            <td class="w-min" />
            <td class="w-100" />
          </tr>
        </template>
      </tbody>
    </table>
    <Loader v-if="isLoading" class="mx-auto w-24 h-24 mt-10" />

    <h4 v-else-if="data.length === 0" class="text-24 text-center pt-8">
      You have no transactions!
    </h4>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import * as api from '@/api';
import { format } from 'date-fns';
import Loader from '@/components/Loader';
import mapTransactionKind from '@/utils/mapTransactionKind';
import redirect from '@/utils/redirect';
import { formatCrypto } from '@/utils/decimal';

const TRANSACTION_REFRESH_INTERVAL_SECONDS = 8;

export default {
  name: 'TransactionHistoryTable',
  components: {
    Loader,
  },
  data() {
    return {
      state: '',
      asset: '',
      type: '',
      lastPage: 1,
      windowWidth: window.innerWidth,
      isLoading: true,
      busy: false,
      hasMore: true,
      isFirstFetch: true,
      timer: null,
      openRows: [],
      data: [],
      rawData: [],
      headers: ['Date', 'State', 'Amount', 'Asset', 'Type', 'Item'],
    };
  },
  computed: {
    renderResponsive() {
      const { windowWidth } = this;
      return windowWidth && windowWidth < 768;
    },
    ...mapGetters({
      user: 'user/getUser',
    }),
    currencyOptions() {
      const options = ['USDT', 'NFT'];
      if (this.user.eth_support_enabled) {
        options.unshift('ETH');
      }
      return options;
    },
    isData() {
      return !!this.data.length;
    },
    showComponent() {
      return (
        this.isData ||
        this.state !== '' ||
        this.asset !== '' ||
        this.type !== ''
      );
    },
  },
  watch: {
    type() {
      this.filterData();
    },
    asset() {
      this.filterData();
    },
    state() {
      this.filterData();
    },
  },
  mounted() {
    this.fetchTransactions(true);
    this.timer = setInterval(
      () => this.fetchTransactions(true),
      TRANSACTION_REFRESH_INTERVAL_SECONDS * 1000,
    );
    window.addEventListener('resize', this.resizeListener);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener);
    clearInterval(this.timer);
  },
  methods: {
    resizeListener() {
      this.windowWidth = window.innerWidth;
    },
    async fetchTransactions(firstPage = false) {
      const isInfiniteScrollFetch = !firstPage && !this.isFirstFetch;
      this.$emit('refetch');

      if (isInfiniteScrollFetch) {
        if (this.timer) clearInterval(this.timer);
        if (this.busy || !this.hasMore) return;
        this.busy = true;
        this.isLoading = true;
      }

      const { results, count } = await api.get('wallet-operations', {
        page: isInfiniteScrollFetch ? this.lastPage + 1 : 1,
      });
      if (results.length <= 0) {
        this.isLoading = false;
        return;
      }

      const data = this.dataReorganisation(results);

      if (isInfiniteScrollFetch) {
        this.rawData = this.rawData.concat(data);
        this.lastPage++;
      } else {
        this.rawData = [...data];
        this.isFirstFetch = false;
      }

      this.hasMore = count > this.rawData.length;

      this.busy = false;

      this.isLoading = false;
      this.filterData();
    },

    formatAmount(amount) {
      return formatCrypto(amount, '');
    },

    dataReorganisation(data) {
      return data.map(transaction => {
        const {
          id,
          kind,
          amount,
          address,
          transaction_id,
          fee,
          state,
          currency,
          created_at,
          token,
        } = transaction;
        const date = format(new Date(created_at), 'dd MMM');
        const detailDate = format(new Date(created_at), 'dd.MM.yyyy kk:mm:ss');
        const isAccountAction = kind === 'DEPOSIT' || kind === 'WITHDRAWAL';

        let sender, receiver;
        if (!(kind === 'DEPOSIT' || kind === 'WITHDRAWAL')) {
          return {
            id,
            isAccountAction,
            sender,
            transaction: transaction_id,
            fee,
            state: state.toLowerCase(),
            receiver,
            asset: currency,
            transactionType: mapTransactionKind(kind),
            amount,
            date,
            detailDate,
            assetID: token.id,
            itemName: token.name,
            itemImg: token.asset_medium,
          };
        }
        if (kind === 'DEPOSIT') {
          sender = address;
          receiver = this.user.deposit_address;
        } else {
          sender = this.user.deposit_address;
          receiver = address;
        }
        return {
          id,
          isAccountAction,
          sender,
          transaction: transaction_id,
          fee,
          assetID: token ? token.id : '',
          itemName: token ? token.name : '',
          itemImg: token ? token.asset_medium : '',
          state: state.toLowerCase(),
          receiver,
          asset: currency,
          transactionType: mapTransactionKind(kind),
          amount,
          date,
          detailDate,
        };
      });
    },
    openRow(rowid, isOpenable) {
      if (!isOpenable) return;

      if (!this.openRows.includes(rowid)) {
        this.openRows.push(rowid);
      } else {
        this.openRows.splice(this.openRows.indexOf(rowid), 1);
      }
    },
    resetFilters() {
      this.asset = '';
      this.state = '';
      this.type = '';
      this.filterData();
    },
    filterData() {
      // TODO: Do this on backend

      let data = [...this.rawData];
      if (this.state) {
        data = data.filter(t => t.state === this.state.toLowerCase());
      }
      if (this.asset) {
        data = data.filter(t => t.asset === this.asset);
      }
      if (this.type) {
        data = data.filter(
          t => t.transactionType === mapTransactionKind(this.type),
        );
      }
      if (
        this.data[0] &&
        this.data.length === data.length &&
        this.data[0].id === data[0].id
      )
        return;
      this.data = data;
    },
    redirectToDetails(id) {
      redirect({
        name: 'TokenDetails',
        params: {
          id,
        },
      });
    },
  },
};
</script>
<style scoped>
.td-max-height-135 {
  max-height: 135px;
  overflow: hidden;
}
.box-10 {
  min-width: 40px !important;
  min-height: 40px !important;
}
</style>
