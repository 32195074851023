<template>
  <div class="md:mx-6 mt-6 py-6 px-6 lg:px-16">
    <AssetTitle v-if="user.email !== ''" title="My Wallets" />
    <WalletListCard v-for="(wallet, i) in wallets" :key="i" :wallet="wallet" />
    <TransactionHistoryTable @refetch="fetchWallets" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as api from '@/api';
import AssetTitle from '@/components/AssetTitle';
import TransactionHistoryTable from '@/components/TransactionHistoryTable';
import WalletListCard from '@/components/cards/WalletListCard';

export default {
  name: 'Home',
  components: {
    AssetTitle,
    WalletListCard,
    TransactionHistoryTable,
  },
  data() {
    return {
      wallets: [],
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
  },
  async mounted() {
    await this.fetchWallets();
  },
  methods: {
    async fetchWallets() {
      const address = this.user.deposit_address;
      try {
        const balances = await api.get('balances');
        this.wallets = Object.entries(balances).map(([currency, balance]) => ({
          currency,
          balance,
          address,
        }));
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
