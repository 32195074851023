<template>
  <div
    class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all mb-8 sm:ml-8 token-card"
    role="dialog"
    aria-modal="true"
    aria-labelledby="modal-headline"
  >
    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
      <div class="flex items-center text-24">
        {{ currency }}
        <img
          v-if="currency === 'ETH'"
          src="@/assets/icons/cryptocurrency/ETH.svg"
          alt="ETH coin"
          class="h-8 w-8 ml-2"
        />
        <img
          v-else
          src="@/assets/icons/cryptocurrency/USDT.svg"
          alt="USDT coin"
          class="h-8 w-8 ml-2"
        />
      </div>
    </div>
    <div class="w-full border-b border-blue-450"></div>
    <div
      class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 text-center flex flex-col items-center space-y-8"
    >
      <div class="text-16">
        Total Balance
        <div class="text-32">{{ totalBalance }}</div>
      </div>
      <div class="text-18 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        Your Wallet Address
        <div
          class="mt-1 flex flex-col sm:flex-row justify-center items-center text-gray-400 xxs:text-14 text-10"
        >
          {{ address }}
          <img
            v-if="showCopyIcon"
            src="@/assets/icons/copy.svg"
            alt="copy address"
            class="sm:ml-2 sm:w-8 sm:h-8 ml-1 w-6 h-6 cursor-pointer"
            @click="copyAddress"
          />
          <img
            v-else
            src="@/assets/icons/copy_success.svg"
            alt="copy address"
            class="ml-2 w-8 h-8 cursor-pointer"
            @click="copyAddress"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatCrypto } from '@/utils/decimal';
import { emitError } from '@/utils/alerts';

export default {
  name: 'TokenListCard',
  props: {
    wallet: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showCopyIcon: true,
    };
  },
  computed: {
    currency() {
      return this.wallet.currency;
    },
    totalBalance() {
      return formatCrypto(this.wallet.balance, this.wallet.currency);
    },
    address() {
      return this.wallet.address;
    },
  },
  methods: {
    copyAddress() {
      navigator.clipboard
        .writeText(this.address)
        .then(() => {
          this.showCopyIcon = false;
        })
        .catch(() => {
          emitError('Failed to copy address');
        });
    },
  },
};
</script>
<style scoped>
.token-card {
  width: 100%;
  max-width: 28rem;
}
</style>
